<template>
<div>
<div class="card p-2 bg-card">
  <div class="row">
      <div class="col-12">
        <div class="d-flex justify-content-between align-items-center">
          <input
            v-model="searchTPR"
            placeholder="Search"
            class="mr-bottom form-control input-sm mr-1 ml-1 mt-1 mb-1"
            style="max-width: 200px"
          />
          <button
            class="btn btn-outline-primary btn-sm float-right mt-1 mb-1 mr-1"
            @click="openAddQuestion"
            :disabled="!this.$store.state.auth.permissions.includes('tpr_questionnaire-create')"
          >
           + Add 
          </button>
        </div>
      </div>
    </div>
</div>
  <div class="card p-2">
    
    <table class="table border mb-1">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">title</th>
          <th scope="col">Published</th>
          <th scope="col">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(qstn, index) in questionnaire" :key="qstn._id">
          <td>{{ (currentPage - 1) * perPage + index + 1 }}</td>
          <td>{{ qstn.title }}</td>
          <td>
            <span v-if="qstn.is_published == 1" class="text-success">
              <feather-icon icon="CheckCircleIcon" /> Published</span
            >
            <span v-else>
              <button
                class="btn btn-primary btn-sm"
                @click="publishModal(qstn._id)"
              >
                Publish
              </button>
            </span>
          </td>
          <td>
            <b-button
              variant="flat-primary"
              class="btn-icon"
              @click="viewQuestionnaire(qstn._id)"
            >
              <feather-icon icon="EyeIcon" />
            </b-button>
            <b-button
              variant="flat-primary"
              class="btn-icon"
              @click="editQuestionnair(qstn._id)"
            >
              <feather-icon icon="Edit2Icon" />
            </b-button>
            <b-button
              variant="flat-danger"
              class="btn-icon"
              @click="deleteModal(qstn._id)"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="row">
      <div class="col-12 float-right">
        <b-pagination
          v-model="currentPage"
          :per-page="perPage"
          hide-goto-end-buttons
          :total-rows="lastPage * perPage"
          class="float-right"
        />
      </div>
    </div>
    <b-sidebar
      id="add-user"
      bg-variant="white"
      right
      backdrop
      shadow
      width="640px"
      v-model="add_qsair"
    >
      <div class="p-2">
        <validation-observer ref="addquestionnair">
          <b-form-group label="Questionnaire" label-for="basicInput">
            <validation-provider
              #default="{ errors }"
              name="Title"
              rules="required"
            >
              <b-form-input
                id="basicInput"
                placeholder="Questionnaire Name"
                v-model="title"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Description" label-for="basicInput">
            <validation-provider
              #default="{ errors }"
              name="Description"
              rules="required"
            >
              <textarea
                name=""
                id=""
                cols="30"
                rows="10"
                placeholder="Description"
                class="form-control"
                v-model="description"
              ></textarea>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <button
            class="btn btn-primary mt-1 mr-1"
            @click.prevent="validationForm"
          >
            Add
          </button>
          <button class="btn btn-secondary mt-1" @click="closeAddQuestion()">
            Cancel
          </button>
        </validation-observer>
      </div>
    </b-sidebar>
    <b-sidebar
      id="add-user"
      bg-variant="white"
      right
      backdrop
      shadow
      width="640px"
      v-model="edit_qsair"
    >
      <div class="p-2">
        <validation-observer ref="editquestionnair">
          <b-form-group label="Questionnaire" label-for="basicInput">
            <validation-provider
              #default="{ errors }"
              name="Title"
              rules="required"
            >
              <b-form-input
                id="basicInput"
                placeholder="Questionnaire Name"
                v-model="title"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Description" label-for="basicInput">
            <validation-provider
              #default="{ errors }"
              name="Description"
              rules="required"
            >
              <textarea
                name=""
                id=""
                cols="30"
                rows="10"
                placeholder="Description"
                class="form-control"
                v-model="description"
              ></textarea>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <button
            class="btn btn-primary mt-1 mr-1"
            @click.prevent="validationFormUpdate"
            :disabled="!this.$store.state.auth.permissions.includes('tpr_questionnaire-update')"
          >
            Update
          </button>
          <button class="btn btn-secondary mt-1" @click="closeeditQuestion()">
            Cancel
          </button>
        </validation-observer>
      </div>
    </b-sidebar>
    <b-modal
      ref="modal-publish"
      title="Are you sure to publish Questionnaire?"
      hide-footer
    >
      <button
        class="btn btn-primary mr-1"
        @click="publishQuestionnaire(qtnaireId)"
        :disabled="!this.$store.state.auth.permissions.includes('tpr_questionnaire-publish')"
      >
        Publish
      </button>
      <button class="btn btn-secondary" @click="closePublishModal">
        Cancel
      </button>
    </b-modal>
    <b-modal
      ref="modal-delete"
      title="Are you sure to Delete Questionnaire?"
      hide-footer
    >
      <button
        class="btn btn-danger mr-1"
        @click="deleteQuestionnaire(qtnaireId)"
        :disabled="!this.$store.state.auth.permissions.includes('tpr_questionnaire-delete')"
      >
        Delete
      </button>
      <button class="btn btn-secondary" @click="closedeleteModal">
        Cancel
      </button>
    </b-modal>
  </div>
  </div>
</template>
<script>
import {
  BFormGroup,
  BFormCheckboxGroup,
  BCardText,
  BFormCheckbox,
  BPagination,
  BSidebar,
  BFormInput,
  BButton,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
export default {
  data() {
    return {
      required,
      add_qsair: false,
      title: "",
      description: "",
      questionnaire: [],
      edit_qsair: false,
      qstnaireid: "",
      qtnaireId: "",
      currentPage: 1,
      lastPage: 1,
      perPage: 10,
      searchTPR:'',
    };
  },
  components: {
    BFormGroup,
    BFormCheckboxGroup,
    BCardText,
    BFormCheckbox,
    BPagination,
    BSidebar,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BButton,
  },
  watch: {
    currentPage(value) {
      this.getQuestionnair(value);
    },
    searchTPR(){
       this.getQuestionnair(this.currentPage);
    }
  },
  mounted() {
    this.getQuestionnair(this.currentPage);
  },
  methods: {
    publishModal(id) {
      this.qtnaireId = id;
      this.$refs["modal-publish"].show();
    },
    deleteModal(id) {
      this.qtnaireId = id;
      this.$refs["modal-delete"].show();
    },
    closedeleteModal() {
      this.$refs["modal-delete"].hide();
    },
    closePublishModal() {
      this.$refs["modal-publish"].hide();
    },
    openAddQuestion() {
      this.add_qsair = true;
      this.title = "";
      this.description = "";
    },
    closeAddQuestion() {
      this.add_qsair = false;
    },
    closeeditQuestion() {
      this.edit_qsair = false;
    },
    validationForm() {
      this.$refs.addquestionnair.validate().then((success) => {
        if (success) {
          // eslint-disable-next-line
          this.addQuestionnair();
        }
      });
    },
    validationFormUpdate() {
      this.$refs.editquestionnair.validate().then((success) => {
        if (success) {
          // eslint-disable-next-line
          this.updateQuestionnair(this.qstnaireid);
        }
      });
    },
    getQuestionnair(pageno) {
      const options = {
        method: "GET",
        params: { page: pageno, limit: this.perPage },
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/tpr_questionnaire?title=${this.searchTPR}`,
      };
      this.$http(options)
        .then((res) => {
          this.questionnaire = res.data.data.data;
          this.currentPage = res.data.data.current_page;
          this.lastPage = res.data.data.last_page;
          this.perPage = res.data.data.per_page;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addQuestionnair() {
      const data = {
        title: this.title,
        description: this.description,
      };
      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/tpr_questionnaire`,
      };
      this.$http(options)
        .then((res) => {
          this.resetform();
          this.closeAddQuestion();
          this.getQuestionnair();
          if (res.data.status == "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "success",
              },
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async resetform() {
      this.title = "";
      this.description = "";
      this.$nextTick(() => this.$refs.addquestionnair.reset());
    },
    editQuestionnair(id) {
      this.edit_qsair = true;
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/tpr_questionnaire/${id}`,
      };
      this.$http(options)
        .then((res) => {
          this.title = res.data.data.questionnaire.title;
          this.description = res.data.data.questionnaire.description;
          this.qstnaireid = res.data.data.questionnaire._id;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateQuestionnair(id) {
      const data = {
        title: this.title,
        description: this.description,
      };
      const options = {
        method: "PUT",
        data: data,
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/tpr_questionnaire/${id}`,
      };
      this.$http(options)
        .then((res) => {
          this.edit_qsair = false;
          this.getQuestionnair();
          if (res.data.status == "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "success",
              },
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteQuestionnaire(id) {
      const options = {
        method: "DELETE",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/tpr_questionnaire/${id}`,
      };
      this.$http(options)
        .then((res) => {
          this.getQuestionnair();
          this.$refs["modal-delete"].hide();
          if (res.data.status == "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    viewQuestionnaire(id) {
      this.$router.push({ name: "tprqts", params: { id: id } });
    },
    publishQuestionnaire(id) {
      const options = {
        method: "POST",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_ONECSF_API_URL +
          `/tpr_questionnaire/${id}/publish`,
      };
      this.$http(options)
        .then((res) => {
          this.$refs["modal-publish"].hide();

          this.getQuestionnair();

          if (res.data.status == "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "success",
              },
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style>
th{
  background: #472183 !important;
  color: #fff;
}
</style>
